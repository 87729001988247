import * as React from 'react'

export const Spark = ({
  title,
  titleId,
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      role={title ? 'img' : 'presentation'}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g fill="currentColor" clipPath="url(#spark_svg__a)">
        <path d="m17.182 13.653-3.624-1.646A3.87 3.87 0 0 1 11.6 9.971l-1.582-3.769a.345.345 0 0 0-.644 0L7.792 9.971a3.87 3.87 0 0 1-1.958 2.036l-3.629 1.646c-.28.127-.283.542 0 .669l3.692 1.697a3.9 3.9 0 0 1 1.949 2.045l1.531 3.696a.344.344 0 0 0 .643 0l1.58-3.764a3.87 3.87 0 0 1 1.958-2.036l3.624-1.646c.282-.13.282-.542 0-.669zm4.598-7.124-2.072-.947a2.2 2.2 0 0 1-1.116-1.168l-.905-2.169a.197.197 0 0 0-.367 0l-.905 2.169a2.2 2.2 0 0 1-1.116 1.168l-2.072.947c-.161.073-.161.312 0 .385l2.11.978c.499.23.896.651 1.113 1.177l.875 2.123a.2.2 0 0 0 .371 0l.901-2.163a2.2 2.2 0 0 1 1.117-1.168l2.072-.947c.161-.073.161-.312 0-.385z" />
      </g>
      <defs>
        <clipPath id="spark_svg__a">
          <path fill="currentColor" d="M2 2h20v20H2z" />
        </clipPath>
      </defs>
    </svg>
  )
}
Spark.displayName = 'Spark'
export default Spark