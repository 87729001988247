import React from 'react'
import PropTypes from 'prop-types'
import { CrossIcon } from '@boltui/icons'

import styles from './ClearSearch.scss'

class ClearSearch extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isHovered: false,
    }
  }

  toggleHover = (isHovered) => {
    this.setState({
      isHovered,
    })
  }

  render() {
    const { onClick } = this.props
    const { isHovered } = this.state
    return (
      <button
        tabIndex='-1'
        type='button'
        className={styles.button}
        onClick={onClick}
        onMouseEnter={() => this.toggleHover(true)}
        onMouseLeave={() => this.toggleHover(false)}
        data-test='search-clear-icon'>
        <span className='sr-only'>Clear Search</span>
        <CrossIcon height='20px' width='20px' className={styles.clearIcon} filled={isHovered} fill='grey' />
      </button>
    )
  }
}

ClearSearch.propTypes = {
  onClick: PropTypes.func,
}

ClearSearch.defaultProps = {
  onClick: () => {},
}

export default ClearSearch
