import React, { Component } from 'react'
import ErrorBoundary from '@argos/error-boundary'
import { Drawer } from '@sainsburys-tech/fable'

import styles from './AskTrevor.scss'

class AskTrevor extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
    }
    this.toggleTrevor = this.toggleTrevor.bind(this)
  }

  componentDidMount() {
    window.addEventListener('message', this.toggleTrevor)

    // Pre-open the drawer if the URL contains the query param 'trevor=open'
    if (window.location.search.includes('trevor=open')) {
      this.setState({ isOpen: true })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.toggleTrevor)
  }

  toggleTrevor(event) {
    if (event.data === 'close-trevor-drawer') {
      this.setState({ isOpen: false })
    }

    if (event.data === 'open-trevor-drawer') {
      this.setState({ isOpen: true })
    }
  }

  render() {
    const { isOpen } = this.state

    return (
      <ErrorBoundary>
        <Drawer
          isOpen={isOpen}
          onClose={() => this.setState({ isOpen: false })}
          position='right'
          padding={{
            none: 'none',
          }}
          size={{
            none: 'full',
            sm: 'lg',
            md: 'md',
            lg: 'md',
          }}
          className={styles.drawer}>
          <iframe
            src='/trevor/chat'
            id='trevor-iframe-embed'
            title='Ask Trevor application'
            loading='lazy'
            style={{ width: '100%', height: '100%' }}
            sandbox='allow-same-origin allow-scripts allow-forms allow-popups'
          />
        </Drawer>
      </ErrorBoundary>
    )
  }
}

export default AskTrevor
