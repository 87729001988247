/* eslint-disable react/forbid-prop-types */
import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { subscribe } from 'react-contextual'
import ErrorBoundary from '@argos/error-boundary'

import MegaMenuCategories from '../MegaMenuCategories/MegaMenuCategories'
import FlyoutMenu from '../FlyoutMenu/FlyoutMenu'
import MegaMenuLevels from '../MegaMenuLevels/MegaMenuLevels'
import MegaMenuShopAll from '../MegaMenuShopAll/MegaMenuShopAll'
import { keyCodes } from '../../helpers/menuKeyboardControls'
import formatDrillDownCategory from '../../helpers/formatDrillDownCategory'
import { isDesktop, isMobile } from '../../helpers/viewPortHelper'

import { NavigationStore } from '../../stores'

export class MegaMenu extends Component {
  static propTypes = {
    activeCategory: PropTypes.shape({
      index: PropTypes.number,
      title: PropTypes.string,
      link: PropTypes.string,
      links: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          link: PropTypes.string,
        }),
      ),
    }),
    activeDrillDownCategory: PropTypes.shape({
      title: PropTypes.string,
      links: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          link: PropTypes.string,
        }),
      ),
      link: PropTypes.string,
    }),
    activeDrillDownLevelTwoCategory: PropTypes.shape({
      title: PropTypes.string,
      links: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          link: PropTypes.string,
        }),
      ),
    }),
    activeFlyoutCategory: PropTypes.shape({
      title: PropTypes.string,
      link: PropTypes.string,
    }),
    focusedItem: PropTypes.number,
    megaButtons: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string,
        link: PropTypes.string,
        links: PropTypes.arrayOf(
          PropTypes.shape({
            text: PropTypes.string,
            link: PropTypes.string,
          }),
        ),
      }),
    ),
    onUpdateActiveCategory: PropTypes.func,
    onUpdateActiveDrillDownCategory: PropTypes.func,
    onUpdateActiveDrillDownLevelTwoCategory: PropTypes.func,
    onUpdateActiveFlyoutCategory: PropTypes.func,
    onUpdateActiveMenu: PropTypes.func,
    shopAllRef: PropTypes.shape({
      current: PropTypes.string,
    }),
    taxonomy: PropTypes.arrayOf(
      PropTypes.shape({
        link: PropTypes.string,
        title: PropTypes.string,
        columns: PropTypes.arrayOf(
          PropTypes.arrayOf(
            PropTypes.shape({
              title: PropTypes.string,
              links: PropTypes.arrayOf(
                PropTypes.shape({
                  link: PropTypes.string,
                  title: PropTypes.string,
                }),
              ),
            }),
          ),
        ),
      }),
    ),
  }

  static defaultProps = {
    taxonomy: [],
    megaButtons: [],
    activeCategory: {
      index: null,
    },
    activeDrillDownCategory: {
      title: null,
      link: null,
      links: null,
    },
    activeDrillDownLevelTwoCategory: {
      title: null,
      links: null,
    },
    activeFlyoutCategory: {
      title: null,
      link: null,
    },
    focusedItem: 0,
    shopAllRef: {},
    onSetRef: () => {},
    onUpdateActiveMenu: () => {},
    onUpdateActiveCategory: () => {},
    onUpdateActiveDrillDownCategory: () => {},
    onUpdateActiveDrillDownLevelTwoCategory: () => {},
    onUpdateActiveFlyoutCategory: () => {},
  }

  constructor(props) {
    super(props)
    this.categoryInTimeout = 25
    this.categoryExclusionZoneTimeout = 750
    this.categoryHoverTimer = null
  }

  handleCategoryIn = (idx, category, evt) => {
    const { taxonomy, onUpdateActiveCategory, onUpdateCategory, shopAllRef } = this.props
    const { title, link, columns } = category
    const exclusionElementTag = 'SPAN'
    let index = idx

    // eslint-disable-next-line no-return-assign, no-shadow
    const isSubmenuNoContent = (link, index, timeout) =>
      (this.categoryHoverTimer = setTimeout(() => onUpdateCategory({ index, title: null, link }), timeout))

    if (isDesktop(global)) {
      const keyOpenEvt = evt.which === keyCodes.SPACE || evt.which === keyCodes.RIGHT
      if (keyOpenEvt || evt.type === 'mouseenter' || evt.type === 'touchstart') {
        evt.preventDefault()

        if (keyOpenEvt) {
          setTimeout(() => {
            shopAllRef && shopAllRef.current.focus()
          }, this.categoryInTimeout + 1)
        }

        if (columns.length === 0) {
          return isSubmenuNoContent(link, index, this.categoryInTimeout)
        }

        if (evt.target.tagName === exclusionElementTag) {
          index += 1
          if (!taxonomy[index]) return null
          // eslint-disable-next-line no-shadow
          const { title, link, columns } = taxonomy[index]

          if (columns.length === 0) {
            return isSubmenuNoContent(link, index, this.categoryExclusionZoneTimeout)
          }

          // eslint-disable-next-line no-return-assign
          return (this.categoryHoverTimer = setTimeout(
            () => onUpdateActiveCategory({ index, title, link }),
            this.categoryExclusionZoneTimeout,
          ))
        }

        // eslint-disable-next-line no-return-assign
        return (this.categoryHoverTimer = setTimeout(
          () => onUpdateActiveCategory({ index, title, link }),
          this.categoryInTimeout,
        ))
      }
    }

    return false
  }

  handleCategoryOut = () => {
    this.props.onToggleCategoryFocus(false)
    clearTimeout(this.categoryHoverTimer)
  }

  handleFlyoutCategoryIn = (idx, category) => {
    this.props.onUpdateActiveFlyoutCategory(category)
  }

  handleFlyoutCategoryOut = () => {
    this.props.onUpdateActiveFlyoutCategory({ text: null, link: null })
  }

  handleChangeDrillDownCategory = (idx, category, newLevel, evt) => {
    const { onUpdateActiveDrillDownLevelTwoCategory, onUpdateActiveDrillDownCategory, activeMenu } = this.props
    if (newLevel === 2) {
      onUpdateActiveDrillDownCategory(formatDrillDownCategory(category))
    }
    if (newLevel === 3) {
      onUpdateActiveDrillDownLevelTwoCategory(category)
    }
    this.updateMenuLevel(activeMenu, newLevel, evt)
  }

  handleBackButton = (newLevel, evt) => {
    const { activeMenu, onUpdateFlyoutMenu } = this.props
    onUpdateFlyoutMenu(false)
    this.updateMenuLevel(activeMenu, newLevel, evt)
  }

  updateMenuLevel = (oldLevel, newLevel, evt) => {
    if (evt) evt.preventDefault()
    const { onUpdateActiveMenu, onUpdatePreviousMenu } = this.props
    onUpdatePreviousMenu(oldLevel)
    onUpdateActiveMenu(evt, newLevel)
  }

  render() {
    const {
      taxonomy,
      activeCategory,
      activeDrillDownCategory,
      activeDrillDownLevelTwoCategory,
      activeFlyoutCategory,
      shopAllRef,
      onSetRef,
      previousMenu,
      activeMenu,
      flyoutActive,
      flyoutFocusedItem,
      megaButtons,
    } = this.props

    const showDrillDownMenu = !isDesktop(global)
    const isSlidingLeft = previousMenu <= activeMenu

    return (
      <Fragment>
        <MegaMenuCategories
          activeCategory={activeCategory}
          animateIn={isMobile(global) || previousMenu === 2} // On tablet, only slide in when going from lower menu level
          animateOut={isMobile(global) || activeMenu !== 1} // On tablet, only slide out if active level
          isActive={activeMenu === 1 && !flyoutActive}
          isSlidingLeft={isSlidingLeft}
          menuLevel={1}
          onBackButtonClick={this.handleBackButton}
          onClick={showDrillDownMenu ? this.handleChangeDrillDownCategory : this.handleCategoryIn}
          onKeyDown={this.handleCategoryIn}
          onMouseEnter={this.handleCategoryIn}
          onMouseLeave={this.handleCategoryOut}
          onSetRef={onSetRef}
          onTouchStart={this.handleCategoryIn}
          showDrillDownMenu={showDrillDownMenu}
          taxonomy={taxonomy}
        />
        {showDrillDownMenu && (
          <Fragment>
            <MegaMenuCategories
              activeCategory={activeDrillDownCategory}
              isActive={activeMenu === 2}
              isSlidingLeft={isSlidingLeft}
              menuLevel={2}
              onBackButtonClick={this.handleBackButton}
              onClick={this.handleChangeDrillDownCategory}
              showDrillDownMenu={showDrillDownMenu}
            />
            <MegaMenuCategories
              activeCategory={activeDrillDownLevelTwoCategory}
              isActive={activeMenu === 3}
              isLastLevel
              isSlidingLeft={isSlidingLeft}
              menuLevel={3}
              onBackButtonClick={this.handleBackButton}
              showDrillDownMenu={showDrillDownMenu}
            />
          </Fragment>
        )}
        {megaButtons.map((button, index) => {
          if (button.links && button.links.length > 0) {
            return (
              <FlyoutMenu
                key={index}
                activeFlyoutCategory={activeFlyoutCategory}
                flyoutFocusedItem={flyoutFocusedItem}
                isActive={activeMenu === 1 && flyoutActive}
                isSlidingLeft={isSlidingLeft}
                links={button.links}
                menuLevel={1}
                onBackButtonClick={this.handleBackButton}
                onSetRef={onSetRef}
                animate={isMobile(global)} // Don't slide in/out on tablet
                onKeyDown={this.handleFlyoutCategoryIn}
                onMouseEnter={this.handleFlyoutCategoryIn}
                onMouseLeave={this.handleFlyoutCategoryOut}
              />
            )
          }
          return null
        })}
        <ul className='mega-menu-category-wrapper'>
          {taxonomy.map((category) => {
            const shopAllCategory = { title: category.title, link: category.link }
            const isActiveCategory = activeCategory.title === category.title && activeCategory.link === category.link
            return (
              <li key={category.title}>
                <ul className='mega-menu-category' data-category-active={isActiveCategory}>
                  <MegaMenuShopAll category={shopAllCategory} shopAllRef={isActiveCategory ? shopAllRef : null} />
                  <MegaMenuLevels category={category} />
                </ul>
              </li>
            )
          })}
        </ul>
      </Fragment>
    )
  }
}

const SubscribedComponent = subscribe([NavigationStore], (navigation) => ({
  activeDrillDownCategory: navigation.activeDrillDownCategory,
  activeDrillDownLevelTwoCategory: navigation.activeDrillDownLevelTwoCategory,
  activeFlyoutCategory: navigation.activeFlyoutCategory,
  activeMenu: navigation.activeMenu,
  flyoutActive: navigation.flyoutActive,
  flyoutFocusedItem: navigation.flyoutFocusedItem,
  onUpdateFlyoutMenu: navigation.onUpdateFlyoutMenu,
  onUpdateActiveDrillDownCategory: navigation.onUpdateActiveDrillDownCategory,
  onUpdateActiveDrillDownLevelTwoCategory: navigation.onUpdateActiveDrillDownLevelTwoCategory,
  onUpdateCategory: navigation.onUpdateActiveCategory,
  onUpdateActiveFlyoutCategory: navigation.onUpdateActiveFlyoutCategory,
  onUpdatePreviousMenu: navigation.onUpdatePreviousMenu,
  previousMenu: navigation.previousMenu,
}))(subscribe()(MegaMenu))

const ProvidedComponent = (props) => (
  <ErrorBoundary>
    <SubscribedComponent {...props} />
  </ErrorBoundary>
)

export default ProvidedComponent
