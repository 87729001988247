import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { subscribe } from 'react-contextual'
import { getSearchPath } from '@sainsburys-tech/boltui-utils'

import sanitiseSearch from '../../helpers/sanitiseSearchTerm'
import { searchUrlBuilder } from '../../helpers/analyticsUrlBuilder'

import SearchStore from '../../stores/Search'
import { SearchForm } from '../../components'

import styles from './Search.scss'

export class Search extends Component {
  static propTypes = {
    chosenSuggestion: PropTypes.string,
    clearSuggestions: PropTypes.func,
    closeSuggestions: PropTypes.func,
    focusedSuggestion: PropTypes.number,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    overrideSearchTerm: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    searchFocused: PropTypes.bool,
    searchValue: PropTypes.string,
    showAutoSuggest: PropTypes.bool,
    updateHasSearched: PropTypes.func,
    updateSearchTerm: PropTypes.func,
  }

  static defaultProps = {
    overrideSearchTerm: '',
    searchValue: '',
    chosenSuggestion: null,
    focusedSuggestion: null,
    searchFocused: false,
    showAutoSuggest: false,
    onFocus: () => {},
    onBlur: () => {},
    updateSearchTerm: () => {},
    updateHasSearched: () => {},
    clearSuggestions: () => {},
  }

  componentDidMount() {
    const { overrideSearchTerm, updateSearchTerm } = this.props
    if (overrideSearchTerm) {
      updateSearchTerm(overrideSearchTerm)
    }
  }

  updateSearchTerm = (evt) => {
    evt.preventDefault()
    this.props.updateSearchTerm(evt.target.value)
    this.props.updateHasSearched(true)
  }

  clearSearchTerm = () => {
    this.props.updateSearchTerm('')
    this.props.clearSuggestions()
  }

  handleSearch = (evt) => {
    evt.preventDefault()
    const { searchValue, updateSearchTerm, overrideOnSearchSubmit, closeSuggestions } = this.props
    const sanitisedSearchVal = sanitiseSearch(searchValue).trim()
    updateSearchTerm(sanitisedSearchVal)

    if (!sanitisedSearchVal) return
    if (overrideOnSearchSubmit && typeof overrideOnSearchSubmit === 'function') {
      closeSuggestions(evt)

      const searchUrl = searchUrlBuilder(sanitisedSearchVal, 'search', null)
      overrideOnSearchSubmit(sanitisedSearchVal, searchUrl, evt)
    } else {
      window.location.href = `${getSearchPath(sanitisedSearchVal)}${searchUrlBuilder(
        sanitisedSearchVal,
        'search',
        null,
      )}`
    }
  }

  render() {
    const { searchValue, chosenSuggestion, focusedSuggestion, searchFocused, showAutoSuggest } = this.props

    return (
      <div className={styles.search} data-search-focused={searchFocused || showAutoSuggest}>
        <SearchForm
          showAutoSuggest={showAutoSuggest}
          focusedSuggestion={focusedSuggestion}
          chosenSuggestion={chosenSuggestion}
          searchValue={searchValue}
          inputRef={this.searchInputRef}
          onClear={this.clearSearchTerm}
          onChange={this.updateSearchTerm}
          onSubmit={this.handleSearch}
          onFocus={this.props.onFocus}
          onBlur={this.props.onBlur}
        />
      </div>
    )
  }
}

const SubscribedComponent = subscribe([SearchStore], (search) => ({
  searchValue: search.searchTerm,
  searchFocused: search.searchFocused,
  showAutoSuggest: search.showAutoSuggest,
  focusedSuggestion: search.focusedSuggestion,
  overrideSearchTerm: search.overrideSearchTerm,
  updateSearchTerm: search.updateSearchTerm,
  updateHasSearched: search.updateHasSearched,
  clearSuggestions: search.clearSuggestions,
  onFocus: () => search.onSearchFocus(search),
  onBlur: () => search.onSearchBlur(),
}))(subscribe()(Search))

export default SubscribedComponent
