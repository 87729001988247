import React, { Component } from 'react'
import ErrorBoundary from '@argos/error-boundary'
import { IconButton } from '@sainsburys-tech/fable'

import Spark from '../../components/Icons/Spark'
import { pushEventThenTrack } from '../../helpers/trackingHelper'

import styles from './AskTrevor.scss'

class AskTrevorCTAButton extends Component {
  componentDidMount() {
    if (typeof window.digitalData !== 'undefined' && !!window.digitalData.track) {
      // Trevor loaded event
      pushEventThenTrack({
        eventInfo: {
          eventAction: 'trevor loaded',
          eventName: 'Trevor interaction - loaded',
        },
      })

      // Trevor impression
      pushEventThenTrack({
        eventInfo: {
          eventAction: 'trevor impression',
          eventName: 'Trevor interaction - impression',
        },
      })
    }
  }

  handleAskTrevorCTAClick = () => {
    // Trevor click event
    pushEventThenTrack({
      eventInfo: {
        eventAction: 'trevor icon clicked',
        eventName: 'Trevor interaction',
      },
    })
  }

  render() {
    return (
      <ErrorBoundary>
        <IconButton
          icon={<Spark />}
          size='sm'
          className={styles.buttonTrevorCTA}
          data-test='ask-trevor-button'
          onClick={() => {
            window.postMessage('open-trevor-drawer')
            this.handleAskTrevorCTAClick()
          }}>
          <span className={styles.buttonTrevorLabel}>Ask Trevor</span>
        </IconButton>
      </ErrorBoundary>
    )
  }
}

export default AskTrevorCTAButton
