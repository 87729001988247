/**
 * Get value of specific cookie
 * @returns {string} The value of the cookie
 */
function getCookieValue(name) {
  const cookies = global.document.cookie
  const cookieByName = cookies.split(';').filter((cookie) => cookie.trim().startsWith(`${name}=`))
  return cookieByName && cookieByName.length ? cookieByName[0].split('=')[1] : ''
}

/**
 * Check if a specific cookie exists by its name
 * @param {string} name - The name of the cookie
 * @returns {boolean} True if the cookie exists, false otherwise
 */
function doesCookieExist(name) {
  return getCookieValue(name) !== ''
}

export { doesCookieExist, getCookieValue }
